<script setup lang="ts">
// utils
import dayjs from '@/utils/day';
import { getNumberOfNights } from '@/utils/dates';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiPromoTileProps {
  availabilityParams?: any;
  campsite: any;
  layout?: 'default' | 'closest-available-campsites';
  snowplowContext?: any;
}
const props = withDefaults(defineProps<CiPromoTileProps>(), {
  layout: 'default',
  snowplowContext: () => {},
  availabilityParams: () => {
    return {
      from: null,
      until: null,
      adults: 2,
      children: '',
      acco_type: null,
    };
  },
});

/********************
 * COMPOSITIONS      *
 ********************/
const appStore = useAppStore();
const localePath = useLocalePath();
const route = useRoute();
const router = useRouter();
const runtimeConfig = useRuntimeConfig();
const { $gettext, $pgettext, $ngettext } = useGettext();
const { fixImageUrlAndCheckWebp } = useWebp();
const { getDecimalSeparator } = usePriceFormatter();
const { getContextForCP } = useECommerceContext();
const { locale } = useI18n();
const { isFavorite, toggleFavorite } = useFavorites({ campsite: props.campsite });
const {
  impressionId,
  trackAdClickAndFollow: DTrackAdClickAndFollow,
  trackAdImpression: DTrackAdImpression,
  trackPromotionClick,
  trackPromotionView,
} = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const tileLink = computed(() => {
  let query = {};
  let hash = '';
  if (props.layout === 'closest-available-campsites') {
    query = props.availabilityParams;
    hash = '#availabilities';
  }

  return localePath({
    name: RBN_CAMPSITE_DETAIL,
    params: { identifier: props.campsite.slug },
    query,
    hash,
  });
});

const comparePrice = computed(() => {
  if (hasPrice(props.campsite.price?.price_off_season)) {
    return props.campsite.price.price_off_season;
  }

  if (hasPrice(props.campsite.price?.price1)) {
    return props.campsite.price.price1;
  }

  return null;
});

const price = computed(() => {
  const decimal = parseFloat(comparePrice.value).toFixed(2);
  return decimal?.split('.');
});

const decimalSeparator = computed(() => {
  return getDecimalSeparator(comparePrice.value);
});

const hasPitches = computed(() => {
  return (props.campsite?.availabilities?.exact?.pitch?.count || 0) > 0;
});

const hasAccos = computed(() => {
  return (props.campsite?.availabilities?.exact?.acco?.count || 0) > 0;
});

const numPitchAvailabilities = computed(() => {
  const pitch = props.campsite?.availabilities?.exact?.pitch;
  if (!pitch) {
    return 0;
  }
  return (pitch.count || 0) * (pitch.stock || 0);
});

const numAccoAvailabilities = computed(() => {
  const acco = props.campsite?.availabilities?.exact?.acco;
  if (!acco) {
    return 0;
  }
  return (acco.count || 0) * (acco.stock || 0);
});

const numAvailabilities = computed(() => {
  return numPitchAvailabilities.value + numAccoAvailabilities.value;
});

const availabilityPrice = computed(() => {
  const pitch = props.campsite?.availabilities?.exact?.pitch;
  const acco = props.campsite?.availabilities?.exact?.acco;
  const defaultCurrency = 'EUR';
  if (!pitch && !acco) {
    return {
      currency: defaultCurrency,
      price: 0,
    };
  }
  if (pitch && acco) {
    return {
      currency: pitch.currency || acco.currency || defaultCurrency,
      price: Math.min(pitch.price || 0, acco.price || 0),
    };
  }
  if (pitch) {
    return {
      currency: pitch.currency || defaultCurrency,
      price: pitch.price || 0,
    };
  }
  return {
    currency: acco.currency || defaultCurrency,
    price: acco.price || 0,
  };
});

const availabilityFrom = computed(() => {
  return props.availabilityParams.from || route.query.from || null;
});

const availabilityUntil = computed(() => {
  return props.availabilityParams.until || route.query.until || null;
});

const fanclubBenefits = computed(() => {
  return props.campsite.fanclub?.benefits || 0;
});

/********************
 * FUNCTIONS         *
 ********************/
function getFormattedDate(date) {
  if (!date) {
    return;
  }
  return dayjs(date).format('DD.MM.YY');
}

function getDayForDate(date, format) {
  if (!date) {
    return;
  }
  return dayjs(date).format(format);
}

function trackAdClickAndFollow(event: MouseEvent) {
  DTrackAdClickAndFollow(event, {
    bannerId: props.snowplowContext.bannerId || null,
    campaignId: props.snowplowContext.campaignId || null,
    impressionId: impressionId.value,
    zoneId: props.snowplowContext.zoneId || null,
    context: [
      {
        schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
        data: {
          booked_products: props.campsite.purchases || null,
          camping_id: props.campsite.camping_id,
          civ2_id: props.campsite.id,
          slug: props.campsite.slug,
        },
      },
      {
        schema: 'iglu:com.camparound/campinginfo_campsite_representation/jsonschema/1-0-2',
        data: {
          awards: props.campsite.ci_award ? [props.campsite.ci_award] : [],
          content: props.campsite.image || null,
          count_exact: null,
          count_flex: null,
          currency: 'EUR',
          favorite: isFavorite.value,
          position: props.snowplowContext.position || null,
          price: comparePrice.value || null,
          rating: props.campsite.rating_avg_overall || null,
        },
      },
    ],
  });
}

function clickAvailability(event: MouseEvent) {
  appStore.clickContext = 'availability';
  trackAdClickAndFollow(event);

  trackPromotionClick({
    id: props.campsite?.camping_id.toString(),
    name: props.snowplowContext?.bannerId,
    type: 'slider',
    position: props.snowplowContext?.position,
    product_ids: [props.campsite?.camping_id.toString()],
    context: getContextForCP(toRaw(props.campsite)),
  });
}

function hasPrice(price) {
  return price && price !== 0;
}

function isVisible(isVisible: boolean) {
  if (isVisible) {
    const envURL = runtimeConfig.public.baseUrl.replace(/\/+$/, '');

    const resolvedRoute = router.resolve(
      localePath({
        name: RBN_CAMPSITE_DETAIL,
        params: { identifier: props.campsite.slug },
      }),
    );
    const targetUrl = `${envURL}${resolvedRoute.href}`;

    DTrackAdImpression({
      bannerId: props.snowplowContext.bannerId,
      campaignId: props.snowplowContext.campaignId,
      impressionId: impressionId.value,
      targetUrl,
      zoneId: props.snowplowContext.zoneId,
      context: [
        {
          schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
          data: {
            booked_products: toRaw(props.campsite.purchases || null),
            camping_id: props.campsite.camping_id,
            civ2_id: props.campsite.id,
            slug: props.campsite.slug,
          },
        },
        {
          schema: 'iglu:com.camparound/campinginfo_campsite_representation/jsonschema/1-0-2',
          data: {
            awards: props.campsite.ci_award ? [props.campsite.ci_award] : [],
            content: props.campsite.image || null,
            count_exact: null,
            count_flex: null,
            currency: 'EUR',
            favorite: isFavorite.value,
            position: props.snowplowContext.position || null,
            price: comparePrice.value || null,
            rating: props.campsite.rating_avg_overall || null,
          },
        },
      ],
    });

    trackPromotionView({
      id: props.campsite?.camping_id.toString(),
      name: props.snowplowContext?.bannerId,
      type: 'slider',
      position: props.snowplowContext?.position,
      product_ids: [props.campsite?.camping_id.toString()],
      context: getContextForCP(toRaw(props.campsite)),
    });
  }
}
</script>

<template>
  <div
    v-observe-visibility="{
      callback: isVisible,
      once: true,
      throttle: 500,
      intersection: {
        threshold: 0.7,
      },
    }"
    :class="`promo-tile relative h-full rounded layout--${layout} bg-white`"
  >
    <nuxt-link
      :to="tileLink"
      class="promo-tile__link block h-full hover:no-underline"
      @click.capture="clickAvailability"
    >
      <div
        class="relative block h-48 overflow-hidden rounded bg-gray-40"
        :class="campsite.image ? 'bg-gray-10' : null"
      >
        <picture v-if="campsite.image">
          <source
            :srcset="`${fixImageUrlAndCheckWebp(campsite.image, true).url}`"
            :type="fixImageUrlAndCheckWebp(campsite.image, true).contentType"
          />
          <source
            :srcset="fixImageUrlAndCheckWebp(campsite.image).url"
            :type="fixImageUrlAndCheckWebp(campsite.image).contentType"
          />
          <img
            v-if="campsite.image"
            :src="fixImageUrlAndCheckWebp(campsite.image).url"
            class="absolute left-0 top-0 size-full object-cover"
            decoding="async"
            :alt="`${campsite.name} image`"
          />
        </picture>
        <div
          class="row relative h-full after:absolute after:left-0 after:top-0 after:block after:size-full after:bg-gradient-to-b after:from-transparent after:from-50% after:to-black after:content-['']"
        >
          <div class="col-12 z-10 flex flex-col">
            <div class="mt-auto p-4">
              <div class="text-input-book font-medium text-primary">
                {{ campsite.city }}
              </div>
              <div class="m-0 font-bold text-white">
                {{ campsite.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="campsite.availabilities"
        class="relative h-[calc(100%-12rem)] p-2"
      >
        <div class="h-full rounded bg-gray-10 p-2">
          <div class="flex">
            <div class="flex-col pr-2">
              <img
                v-if="hasPitches"
                src="~/assets/svg/icon-pitch.svg"
                width="32"
                height="auto"
                class="mb-2 block w-8"
                alt="icon pitch"
                loading="lazy"
              />
              <img
                v-if="hasAccos"
                src="~/assets/svg/icon-cabin.svg"
                width="32"
                height="auto"
                class="block w-8"
                alt="icon cabin"
                loading="lazy"
              />
            </div>
            <div class="campsite-tile__availability-details-container flex-col pl-2">
              <div class="availability__date">
                <span class="whitespace-nowrap">
                  <span class="date font-medium">{{ getFormattedDate(availabilityFrom) }}&nbsp;</span>
                  <span class="text-small-book text-gray">{{ getDayForDate(availabilityFrom, 'dd') }}&nbsp;</span>
                  <span class="date font-medium">- {{ getFormattedDate(availabilityUntil) }}&nbsp;</span>
                  <span class="text-small-book text-gray">{{ getDayForDate(availabilityUntil, 'dd') }}</span>
                </span>
                <div class="text-small-book nights whitespace-nowrap !leading-5 text-gray">
                  {{
                    $ngettext(
                      '(%{ count } night)',
                      '(%{ count } nights)',
                      getNumberOfNights(availabilityFrom, availabilityUntil),
                      { count: getNumberOfNights(availabilityFrom, availabilityUntil) },
                    )
                  }}
                </div>
              </div>
              <div
                v-if="numAvailabilities"
                class="availability__stock text-small-book"
                :class="{ 'text-gray': numAvailabilities > 5, 'text-danger': numAvailabilities <= 5 }"
              >
                <template v-if="numAvailabilities <= 5">
                  <span v-if="numPitchAvailabilities && numAccoAvailabilities">
                    {{
                      $ngettext(
                        'Only %{stock} pitch & rental accommodation available',
                        'Only %{stock} pitches & rental accommodations available',
                        numAvailabilities,
                        { stock: numAvailabilities },
                      )
                    }}
                  </span>
                  <span v-else-if="numPitchAvailabilities">
                    {{
                      $ngettext('Only %{stock} pitch available', 'Only %{stock} pitches available', numAvailabilities, {
                        stock: numAvailabilities,
                      })
                    }}
                  </span>
                  <span v-else>
                    {{
                      $ngettext(
                        'Only %{stock} rental accommodation available',
                        'Only %{stock} rental accommodations available',
                        numAvailabilities,
                        {
                          stock: numAvailabilities,
                        },
                      )
                    }}
                  </span>
                </template>
                <template v-else>
                  <span v-if="numPitchAvailabilities && numAccoAvailabilities">
                    {{ $gettext('%{stock} pitches & rental accommodations available', { stock: numAvailabilities }) }}
                  </span>
                  <span v-else-if="numPitchAvailabilities">
                    {{ $gettext('%{stock} pitches available', { stock: numAvailabilities }) }}
                  </span>
                  <span v-else>{{
                    $gettext('%{stock} rental accommodations available', { stock: numAvailabilities })
                  }}</span>
                </template>
              </div>
              <div class="price">
                <span class="mr-1">{{ $pgettext('Campsite Tile availability price', 'from') }}</span>
                <span class="whitespace-nowrap">{{
                  new Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: availabilityPrice.currency || 'EUR',
                  }).format(availabilityPrice.price)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="relative p-4"
      >
        <CiRatingRadial
          v-if="layout === 'default'"
          v-once
          :rating="campsite.rating_avg_overall"
          class="chart-wrapper absolute top-1/2 size-[3.8rem] -translate-y-1/2 p-0"
          font-class="font-bold"
        />
        <div
          class="flex min-h-14 justify-end rounded bg-gray-20 py-1 pr-2 text-black"
          style="margin-left: calc(3.8rem / 2)"
        >
          <div
            v-if="comparePrice"
            class="flex"
          >
            <span class="mb-1 mr-2 h-8 self-end text-xs font-medium leading-10">{{ $gettext('From') }}</span>
            <div class="mb-0 self-center text-[2.5rem]">
              <span>{{ price[0] }}</span><span>{{ decimalSeparator }}</span>
            </div>
            <span class="font-medium">{{ price[1] }}</span>
            <span class="mb-0 ml-1 self-center text-[2.5rem]">€</span>
          </div>
          <div
            v-else
            class="mb-0 mr-4 h-full self-center text-[2.5rem]"
          >
            -
          </div>
        </div>
      </div>
    </nuxt-link>

    <!-- rating radial -->
    <CiRatingRadial
      v-if="layout === 'closest-available-campsites'"
      v-once
      :rating="campsite.rating_avg_overall"
      :smaller="true"
      class="chart-wrapper absolute left-2.5 top-2 size-[2.8rem] p-0 text-sm"
      font-class="font-bold"
    />

    <!-- award -->
    <div
      v-if="layout === 'closest-available-campsites' && campsite.ci_award"
      class="absolute left-[3.8rem] top-2 size-[2.8rem] rounded-full bg-white"
    >
      <div class="promo-tile__award-logo-wrapper">
        <img
          class="promo-tile__award-logo"
          src="~/assets/svg/award.svg"
          width="100%"
          height="100%"
          alt="Camping.info Award"
        />
      </div>
    </div>

    <!-- favorite -->
    <CiCampsiteFavorite
      class="absolute right-4 top-4 z-20"
      :active="isFavorite"
      :show-label="false"
      @click="toggleFavorite"
    />

    <!-- fanclub -->
    <div
      v-if="fanclubBenefits > 0"
      class="pointer-events-none absolute left-0 top-0 inline-flex p-1"
    >
      <img
        src="~/assets/svg/fanclub-logo-small.svg"
        loading="lazy"
        alt="Fanclub logo"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.promo-tile {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  transform: translateZ(0);
}

.promo-tile .campsite-tile__availability-details-container {
  width: calc(100% - 32px);
}

.promo-tile .campsite-tile__availability-details-container .availability__date {
  line-height: 1;
  margin-bottom: 5px;
}

.promo-tile .campsite-tile__availability-details-container .availability__date .date,
.promo-tile .campsite-tile__availability-details-container .price {
  @apply text-xs text-black;
}

@media screen(sm) {
  .promo-tile .campsite-tile__availability-details-container .availability__date .date,
  .promo-tile .campsite-tile__availability-details-container .price {
    @apply text-sm;
  }
}

.promo-tile.layout--closest-available-campsites {
  @apply cursor-pointer;
}
</style>
