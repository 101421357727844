<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiPromoSliderProps {
  campsites?: any[];
  size?: number;
  useContainer?: boolean;
}
const props = withDefaults(defineProps<CiPromoSliderProps>(), {
  campsites: () => [],
  size: 3,
  useContainer: true,
});

/********************
 * COMPOSITIONS      *
 ********************/
const slots = useSlots();

/********************
 * REFS & VARS       *
 ********************/
const hasHeadlineSlot = computed(() => {
  return !!slots.headline;
});
</script>

<template>
  <section
    v-if="props.campsites?.length > 0"
    class="section section--promo-slider my-12 pb-0"
    :class="`promo-slider__size promo-slider__size--${size}`"
  >
    <div
      v-if="hasHeadlineSlot"
      class="container"
    >
      <slot name="headline" />
    </div>

    <div
      class="relative"
      :class="[
        props.useContainer ? 'promo-slider__container p-0 lg:m-auto lg:max-w-[930px] xl:max-w-[1110px]' : 'px-4',
      ]"
    >
      <CiCssSlider>
        <div
          v-for="(campsite, index) in props.campsites"
          :key="campsite.id"
          class="slide"
        >
          <slot
            name="slide"
            :campsite="campsite || {}"
            :index="index"
          />
        </div>
      </CiCssSlider>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.promo-slider__container {
  .slide {
    scroll-snap-align: center;
  }

  .slide:first-of-type {
    margin-left: 15px;
  }

  .slide:last-of-type {
    margin-right: 15px;
  }

  @include media-breakpoint-up(sm) {
    .slide {
      scroll-snap-align: center;
    }

    .slide:first-of-type {
      margin-left: calc((100vw - 540px) / 2 + 15px);
    }

    .slide:last-of-type {
      margin-right: calc((100vw - 540px) / 2 + 15px);
    }
  }

  @include media-breakpoint-up(md) {
    .slide {
      scroll-snap-align: center;
    }

    .slide:first-of-type {
      margin-left: calc((100vw - 720px) / 2 + 15px);
    }

    .slide:last-of-type {
      margin-right: calc((100vw - 720px) / 2 + 15px);
    }
  }

  @include media-breakpoint-up(lg) {
    .slide {
      scroll-snap-align: start;
    }

    .slide:first-of-type {
      margin-left: 0;
    }

    .slide:last-of-type {
      margin-right: 0;
    }
  }
}

.promo-slider__size {
  &--2 {
    .slide {
      flex: 1 0 auto;
      position: relative;
      scroll-snap-align: start;
      width: 90%;

      @include media-breakpoint-up(md) {
        width: calc(50% - 7.5px);
      }
    }
  }

  &--3 {
    .slide {
      flex: 1 0 300px;
      position: relative;

      @include media-breakpoint-up(md) {
        flex: 1 0 320px;
      }

      @include media-breakpoint-up(lg) {
        flex: 1 0 300px;
      }

      @include media-breakpoint-up(xl) {
        flex: 1 0 360px;
      }
    }
  }
}
</style>
